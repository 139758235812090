export default {
  auth: {
    cancelled_reservation: 'Tu reservación ha sido cancelada, si crees que esto es un error, por favor llámanos o envíanos un mensaje a través de WhatsApp',
    wrong_password: 'Contraseña incorrecta',
    user_not_found: 'No reconocemos este usuario',
    user_disabled: 'Tu acceso ha sido deshabilitado por un administrador',
    logoutTitle: 'Cerrar sesión',
    logoutButton: 'Salir'
  },
  login: {
    ios: {
      title: 'Descarga nuestra app para iOS',
      text: 'Puedes descargar nuestra app nativa para iOS o continuar en el navegador de tu teléfono',
      confirmButtonText: 'Descargar la app (Recomendado)',
      cancelButtonText: 'Continuar en el navegador',
    },
    android: {
      title: 'Descarga nuestra app para Android',
      text: 'Puedes descargar nuestra app nativa para Android o continuar en el navegador de tu teléfono',
      confirmButtonText: 'Descargar la app (Recomendado)',
      cancelButtonText: 'Continuar en el navegador',
    },
    adminAccessModal: {
      title: 'Solo personal autorizado',
      text: 'Esta sección es únicamente para personal autorizado, si eres un huesped, porfavor vuelve al acceso para huespedes y continua con tu proceso de check-in',
      confirmButtonText: 'Acceso Admin',
      cancelButtonText: 'Acceso Huésped',
    },
    noCredentials: 'Credenciales inválidas, pedimos disculpas por el inconveniente, por favor solicita ayuda en la recepción',
    loadingMsg: 'Por favor, espera mientras verificamos la información de tu reserva',
    advice: 'Iniciá tu check-in',
    tapMeToStart: 'Tocar para iniciar',
    scanButtonLabel: 'Escanea nuestro QR Mágico',
    scanAsGuest: 'O accede como acompañante',
    infoCards: {
      a: 'Te damos la bienvenida a la familia Treebū!',
      b: 'Disfruta de las facilidades de nuestra app Treebū',
      c: 'Mantente en contacto con nosotros directamente',
      d: 'Experimenta un proceso de check-in sin contacto y moderno',
    }
  },
  checkin: {
    slideA: {
      title: 'Iniciemos con tu información básica'
    },
    slideB: {
      title: '¿Desde dónde nos visitas?'
    },
    slideC: {
      title: '¿Tienes algún requerimiento en especial?'
    },
    slideD: {
      title: '¿Has llegado manejando un vehículo?'
    },
    slideE: {
      title: '¿Quien o quienes viajan contigo?'
    },
    slideF: {
      title: 'Por favor, lee cuidadosamente nuestros términos y condiciones y acéptalos'
    },
    pleaseFillAllRequiredFields: 'Por favor llena todos los campos requeridos',
    pleaseDrawYourSignature: 'Por favor firma para aceptar nuestros términos y completar el proceso de check-in',
    updatingYourEmail: 'Actualizando tu correo',
    documentTypes: {
      dni: 'Documento nacional de identificación',
      passport: 'Pasaporte',
      dimex: 'DIMEX',
      driverLicense: 'Licencia de Conducir'
    }
  },
  inputs: {
    email: {
      label: 'Email',
      hint: 'Por favor, indicanos tu correo electrónico',
      validation: {
        invalid: 'Correo inválido',
        required: 'Tu correo electrónico es requerido'
      },
    },

    fullName: {
      label: 'Nombre completo',
      hint: 'Verifica que tu nombre esté correctamente escrito.',
      validation: {
        required: 'Tu nombre completo es requerido'
      },
    },

    phone: {
      label: 'Teléfono',
    },

    country: {
      label: 'País',
      hint: '¿Cuál es tu país de residencia?.',
      validation: {
        required: 'Este dato es obligatorio'
      },
    },

    documentType: {
      label: 'Tipo de identificación',
      hint: 'Indicanos que tipo de documento de identificación nos vas a proveer.',
    },

    documentID: {
      label: 'Número de identificación / Pasaporte',
      hint: 'Cédula de identificación o pasaporte',
    },

    allergies: {
      label: 'Alergias',
      hint: 'Por favor, indicanos las alergias que padezcas, presiona enter/intro para agregar cada una (opcional)',
    },

    notes: {
      label: 'Notas',
      hint: 'Si deseas agregar algun dato adicional que debamos saber, puedes escribirlo en este campo (opcional)',
    },

    carPlate: {
      label: 'Placa del vehículo',
      hint: 'Si has llegado manejando un vehículo, indicanos, el numero de matricula o placa',
    },
  },
  common: {
    pleaseRotate: 'Por favor gira tu dispositivo a modo vertical para una mejor experiencia.',
    hints: {
      optional: 'Este campo es opcional',
    },
    validation: {
      required: 'Este campo es requerido'
    },
    qrScanner: 'Escáner QR',
    unknownError: 'Un error desconocido ha pasado, nos disculpamos por cualquier inconveniente.'
  },
  whatsapp: {
    name: 'Hola!, mi nombre es  ',
    rNumber: 'mi numero de reserva es  ',
    roomNumber: 'mi numero de habitación es ',
    mainText: 'Hola!, mi nombre es {name}, mi número de reserva es {rNumber}!',
    callToActionText: 'Chat Treebū'
  },
  general: {
    logoutConfirmation: {
      title: 'Cerrar sesión',
      message: 'Estás por cerrar la sesión de tu dispositivo, si haces esto, necesitarás el código QR proveído por recepción para poder iniciar sesión nuevamente',
      confirmButtonText: 'Salir',
      cancelButtonText: 'Cancelar',
    },
    pleaseScrollVertical: 'Por favor desliza hacia abajo para llenar el resto del formulario de ser necesario',
    cancel: 'Cancelar',
    error: 'El siguiente error ha sucedido: {errorMessage}',
    qrPermission: 'Por favor, acepta los permisos para usar la camara cuando lo solicite, esto se usa únicamente para escanear el código QR!',
    qrCameraAdvice: 'Por favor, dirige la camara de tu teléfono hacia el código QR',
    workingOnIt: 'Oups!, aún estámos trabajando en esta funcionalidad, nos disculpamos por calquier inconveniente',
    name: 'Nombre',
    themeSwitcherDesc: 'Oscuro / Claro',
    appSettings: 'Ajustes de aplicación',
    adult: 'Adulto',
    adults: 'Adultos',
    teenager: 'Adolescente',
    teenagers: 'Adolescentes',
    child: 'Niño',
    children: 'Niños',
    infant: 'Infante',
    infants: 'Infantes',
    mainRoom: 'Habitación principal',
    yes: 'Si',
    no: 'No',
    ok: 'Ok',
    extraRooms: 'Habitaciones adicionales',
    room: 'Habitación | Habitaciones',
    acceptTerms: 'Acepto todos los términos y condiciones',
    drawYourSignature: 'Dibuja tu firma',
    weatherForecast: 'Pronóstico del clima',
    yourReservation: 'Tu Reservación',
    checkIn: 'Entrada',
    checkOut: 'Salida',
    rules: 'Reglas',
    signature: 'Firma',
    noInternet: 'Oups!, parece que no tienes conexión a internet, conéctate a internet para seguir utilizando Treebū Guest App',
    language: 'Idioma',
    'noResults': 'No pudimos encontrar ningún resultado, inténtalo de nuevo',
    'treebuHotelsText': 'Treebū Hotels',
    'qrScannerText': 'Escáner QR',
    'covidFreeText': 'Covid Free',
    'toursText': 'Tours',
    'spaText': 'Spa',
    'restaurantText': 'Restaurante',
    'informationText': 'Info',
    'activitiesText': 'Actividades',
    'promosText': 'Ofertas especiales',
    'welcomeText': 'Pura Vida!',
    'morning': 'Buenos días',
    'afternoon': 'Buenas tardes',
    'evening': 'Buenas noches',
    'roomReady': 'Pura vida',
    'roomNotReady': 'Habitación lista en'
  },
};
