import { defineStore } from 'pinia';
import { auth, db, getCurrentFirebaseState } from 'src/boot/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  updateEmail,
} from 'firebase/auth';
import { Dialog, Loading, Notify } from 'quasar';

import { i18n } from 'boot/i18n';
import { useCommonStore } from 'stores/common-store';
import { useGuestStore } from 'stores/guest-store';
import { forceReload } from 'src/utils/helpers';
import * as Sentry from '@sentry/vue';
const { t } = i18n.global;
import { H } from 'highlight.run';
// import { useIsCurrentUserLoaded } from 'vuefire';
import { handle_auth_error } from 'src/utils/firebase/helpers';
import dayjs from 'dayjs';

// Función auxiliar para crear notificaciones
const createNotification = (type: string, icon: string, message: string) => {
  Notify.create({
    type,
    icon,
    message,
    actions: [
      {
        label: t('general.ok'),
        color: 'white',
        handler: () => { /* ... */ },
      },
    ],
  });
};

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    isAuthenticated: false,
    user: <any>null,
    pending: true,
    credentials: {
      username: '',
      password: '',
      hotel: ''
    },
    uid: '',
    listeners: {
      onAuthStateChanged: null as (() => void) | null,
    }
  }),
  getters: {
    currentUid() {
      return auth.currentUser?.uid
    },
    currentEmail() {
      return auth.currentUser?.email
    }
  },
  actions: {
    async reAuthenticateUser(email: string, password: string, nextAction: string, ) {
      if (auth.currentUser) {
        const credentials = EmailAuthProvider.credential(email, password);
        try {
          const reAuth = await reauthenticateWithCredential(auth.currentUser, credentials);
          this.uid = reAuth.user.uid
          //Forzar el Refrescamiento del token para validar las peticiones
          const newIdToken = await auth.currentUser.getIdToken(true);
        } catch (error: any) {
          createNotification('negative', 'mdi-alert', t('general.error') + ` ${JSON.stringify(error)}`);
          await this.doLogin(email, password)
          throw error
        } finally {
          //
        }
      }
      switch (nextAction) {
        case 'emailUpdate':
          return this.emailUpdate(email, password);
        default:
          return
      }
    },

    async emailUpdate(email: string, password: string) {
      const authStore = useAuthStore();
      const guestStore = useGuestStore();
      if (auth.currentUser !== null) {
        try {
          await updateEmail(auth.currentUser, email);
          authStore.credentials.username = email

          await this.reAuthenticateUser(email, password, 'none');
          this.identifyUser(email)

          await guestStore.updateData({ email: email })

        } catch (error: any) {
          await this.handleEmailUpdateError(error, email, password); // extracted code
        } finally {
          //
        }
      }
    },

    identifyUser(email: string) {
      if (auth.currentUser?.uid) {
        H.identify(email, { id: auth.currentUser.uid });
        Sentry.setUser({ id: auth.currentUser.uid, email: email });
      }
    },

    async handleEmailUpdateError(error: any, email: string, password: string) {
      let message = '';
      switch (error.code) {
        case 'auth/email-already-in-use':
          message = `An error occurred while trying to update the email: ${error.message}, please try again with another email`;
          Dialog.create({ title: 'Error', message, ok: true, persistent: true }).onOk(forceReload).onCancel(forceReload).onDismiss(forceReload);
          break;
        case 'auth/requires-recent-login':
          createNotification('warning', 'mdi-alert', 'Re-authenticating and saving your data');
          await this.reAuthenticateUser(email, password, 'updateEmail');
          break;
        default:
          createNotification('negative', 'mdi-alert', `An error occurred while trying to update the email: ${error.message}`);
          break;
      }
    },

    async doLoginIfNeeded(email:string, password:string) {
      console.log('doLoginIfNeeded -> the currentUser status is: ', auth.currentUser)
      if (auth.currentUser) { // Verifica si ya hay un usuario autenticado
        console.log('doLoginIfneeded -> auth.currentUser is valid')
        const token = await auth.currentUser.getIdTokenResult(true); // Fuerza actualización del token
        console.log('This is the token: ', token)
        if (Number(token.expirationTime) < Date.now() / 1000 + 60) { // Re-autentica si el token expira en menos de 60 segundos
          console.log('looks like the token is about to expire, proceeding to login again')
          await signInWithEmailAndPassword(auth, email, password);
        }
      } else {
        console.log('doLoginIfNeeded -> the auth.currentUser status is null, proceeding to login again.');
        await signInWithEmailAndPassword(auth, email, password);
      }
      await getCurrentFirebaseState();
    },

    async doLogin(email: string, password: string) {
      const commonStore = useCommonStore();
      console.log('Logging in');
      try {
        await signInWithEmailAndPassword(auth, email, password);
        await getCurrentFirebaseState();
        commonStore.$patch({
          arrivalTime: dayjs().toDate()
        })
      } catch (e: any) {
        handle_auth_error(e)
      }
    }
  },
  persist: {
    key: 'auth-store',
    storage: localStorage,
    paths: ['isAuthenticated', 'uid', 'credentials', 'user'],
  }
});
