export default {
  auth: {
    cancelled_reservation: 'Your reservation has been cancelled, if you think this is an error, please call us',
    wrong_password: 'Wrong password',
    user_not_found: 'Wrong user',
    user_disabled: 'Your access has been disabled by an administrator',
    logoutTitle: 'End session',
    logoutButton: 'Logout'
  },
  login: {
    ios: {
      title: 'Get our iOS app',
      text: "You can rather download our native app, or continue in your phone's browser",
      confirmButtonText: 'Get the native app (Recommended)',
      cancelButtonText: 'Continue in the browser',
    },
    android: {
      title: 'Get our Android app',
      text: "You can rather download our native app, or continue in your phone's browser",
      confirmButtonText: 'Get the native app (Recommended)',
      cancelButtonText: 'Continue in the browser',
    },
    adminAccessModal: {
      title: 'Authorized staff only',
      text: 'This section is for authorized personnel only, if you are a guest, please cancel this action and continue with your check-in process',
      confirmButtonText: 'Admin Access',
      cancelButtonText: 'Guest Access',
    },
    noCredentials: 'Invalid credentials, we apologize for the inconvenience, please ask for help at the front desk',
    loadingMsg: 'Loading, please wait while we check your information',
    advice: "Let's start your check-in process",
    tapMeToStart: 'Tap me to start',
    scanButtonLabel: 'Scan our magic QR code',
    scanAsGuest: 'Or access as a companion',
    infoCards: {
      a: 'Welcome to our Treebū Family!',
      b: "Take advantage of all facilities you'll find right in our mobile app",
      c: 'Keep in touch with us directly',
      d: 'Experience a contactless and modern check-in process',
    }
  },
  checkin: {
    slideA: {
      title: "Let's start with your basic information"
    },
    slideB: {
      title: 'From where are you visiting us?'
    },
    slideC: {
      title: 'Do you have any special requirement?'
    },
    slideD: {
      title: 'Did you arrive driving a vehicle?'
    },
    slideE: {
      title: 'Who is traveling with you?',
    },
    slideF: {
      title: 'Please read carefully our terms and accept them'
    },
    pleaseFillAllRequiredFields: 'Please fill all required fields',
    updatingYourEmail: 'Updating your email',
    pleaseDrawYourSignature: 'Please draw your signature to complete the check-in process',
    documentTypes: {
      dni: 'National Identification Number',
      passport: 'Passport',
      dimex: 'DIMEX',
      driverLicense: 'Driver License'
    }
  },
  inputs: {
    email: {
      label: 'Email',
      hint: 'Please let us know your email address.',
      validation: {
        invalid: 'Invalid email',
        required: 'We require your email'
      },
    },

    fullName: {
      label: 'Full name',
      hint: 'Please check that your name is correctly written.',
      validation: {
        required: 'Your full name is required'
      },
    },

    phone: {
      label: 'Phone',
    },

    country: {
      label: 'Country',
      hint: "What's your country of residence?.",
      validation: {
        required: 'The country is required'
      },
    },

    documentType: {
      label: 'Identification type',
      hint: 'Please let us know what kind of identification document will you provide us.',
    },

    documentID: {
      label: 'ID / Passport number',
      hint: 'Please let us know your national identification, or passport number, we require this for safety purposes',
    },

    allergies: {
      label: 'Allergies',
      hint: 'Please let us know if you or anyone traveling with you have any allergy, press enter/intro after each (optional)',
    },

    notes: {
      label: 'Notes',
      hint: 'If you need to add some extra information that we should know (optional)',
    },

    carPlate: {
      label: 'Car plate',
      hint: 'If you arrived in a vehicle please let us know the plate number',
    },
  },
  common: {
    pleaseRotate: 'Please rotate your device to portrait mode for a better experience.',
    hints: {
      optional: 'This field is optional',
    },
    validation: {
      required: 'This field is required'
    },
    qrScanner: 'QR Scanner',
    unknownError: 'An error just happened, we apologize for any inconvenience, please let our team know about this error in order to assist you.'
  },
  whatsapp: {
    name: 'Hi!, my name is ',
    rNumber: 'my reservation number is ',
    roomNumber: 'my room number is ',
    mainText: 'Hi!, my name is {name}, my reservation number is {rNumber}!',
    callToActionText: 'Chat with us!'
  },
  general: {
    logoutConfirmation: {
      title: 'Logout Confirmation',
      message: "Are you sure you want to logout?, if you logout, you'll need the QR code provided at FrontDesk to login back again",
      confirmButtonText: 'Logout',
      cancelButtonText: 'Cancel',
    },
    pleaseScrollVertical: 'Please scroll down inside the highlighted area to fill all fields if needed',
    cancel: 'Cancel',
    error: 'Oops something happened: {errorMessage}',
    qrPermission: 'Please, accept the permission to use the camera when prompted, this is merely to scan the QR code',
    qrCameraAdvice: "Please point your phone's camera to the QR code",
    workingOnIt: 'Oops!, we are still working on this functionality, we apologize for any inconvenience',
    name: 'Name',
    themeSwitcherDesc: 'Dark / Light',
    appSettings: 'App Settings',
    adult: 'Adult',
    adults: 'Adults',
    teenager: 'Teenager',
    teenagers: 'Teenagers',
    child: 'Child',
    children: 'Children',
    infant: 'Infant',
    infants: 'Infants',
    mainRoom: 'Main Room',
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    extraRooms: 'Extra rooms',
    room: 'Room | Rooms',
    acceptTerms: 'I Accept all terms and policies',
    drawYourSignature: 'Draw your signature',
    weatherForecast: 'Weather forecast',
    yourReservation: 'Your reservation',
    checkIn: 'Check-in',
    checkOut: 'Check-out',
    rules: 'Rules',
    signature: 'Signature',
    noInternet: 'Oops!, looks like you are not connected to internet, please connect to internet to use our Treebū Guest App',
    language: 'Language',
    'noResults': "We couldn't find any result, please try again",
    'treebuHotelsText': 'Treebū Hotels',
    'qrScannerText': 'QR Scanner',
    'covidFreeText': 'Covid Free',
    'toursText': 'Tours',
    'spaText': 'Spa',
    'restaurantText': 'Restaurant',
    'informationText': 'Info',
    'activitiesText': 'Activities',
    'promosText': 'Special Offers',
    'welcomeText': 'Welcome and Pura Vida!',
    'morning': 'Good morning',
    'afternoon': 'Good afternoon',
    'evening': 'Good evening',
    'roomReady': 'Pura vida',
    'roomNotReady': 'Room ready in'
  },
};
