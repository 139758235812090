import { db } from 'src/boot/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { Platform } from 'quasar';
import { sanitizeObject } from 'src/utils/helpers';
import dayjs from 'dayjs';

export const saveErrorLog = async (hotel: string, uid: string, ref: string, error: any) => {
  const guestRef = collection(db, 'error_logs');
  // Break down complex expression into multi-line for better readability
  // const cleanErrorObject = Object.entries(error).reduce((a: any, [k, v]) => {
  //   if(v) a[k] = v;
  //   return a;
  // }, {});

  const logData = {
    timeStamp: dayjs().toDate(),
    platform: Platform.is ? Platform.is : null,
    ref: ref ? ref : null,
    error: !!error ? JSON.stringify(error) : null,
    hotel: hotel ? hotel : null,
    uid: uid ? uid: null
  }

  await addDoc(guestRef, logData);
}
