<template>
  <q-dialog @before-show="userPrompt" fullscreen v-model="commonStore.modals.qr.active" transition-show="scale" transition-hide="scale">
    <q-card style="width: 500px; max-width: 80vw; border-radius: 30px">
      <q-card-section>
        <div style="margin-bottom: -20px" class="text-overline text-center">QR Scanner</div>
      </q-card-section>
      <q-card-section>
        <qrcode-stream @camera-on="onReady" :track="paintBoundingBox" @detect="onDetect" @error="onError"></qrcode-stream>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script setup lang="ts">
import { defineComponent, ref } from 'vue';
import { useCommonStore } from 'stores/common-store';
import { useGuestStore } from 'stores/guest-store';
import { useAuthStore } from 'stores/auth-store';
const guestStore:any = useGuestStore();
const commonStore:any = useCommonStore();
const authStore:any = useAuthStore();
const {t} = useI18n({});

import {QrcodeStream} from 'vue-qrcode-reader';
import {useI18n} from 'vue-i18n';
import {isURL} from 'src/utils/helpers';
import { openURL, useQuasar } from 'quasar';
const $q = useQuasar();
const userPrompt = () => {
  alert(t('general.qrPermission'))
}

const isValid = ref(undefined)

const resetValidationState = () => {
  isValid.value = undefined
}

const onReady = async (promise:any) => {
  // alert(t('general.qrCameraAdvice'))
  $q.notify({
    message: t('general.qrCameraAdvice'),
    color: 'primary',
    icon: 'mdi-qrcode-scan',
    position: 'top',
    timeout: 5000,
  })
  resetValidationState()
}

const result = ref('')
const error = ref('')
const onError = (err:any) => {
  error.value = `[${err.name}]: `

  if (err.name === 'NotAllowedError') {
    error.value += 'you need to grant camera access permission'
  } else if (err.name === 'NotFoundError') {
    error.value += 'no camera on this device'
  } else if (err.name === 'NotSupportedError') {
    error.value += 'secure context required (HTTPS, localhost)'
  } else if (err.name === 'NotReadableError') {
    error.value += 'is the camera already in use?'
  } else if (err.name === 'OverconstrainedError') {
    error.value += 'installed cameras are not suitable'
  } else if (err.name === 'StreamApiNotSupportedError') {
    error.value += 'Stream API is not supported in this browser'
  } else if (err.name === 'InsecureContextError') {
    error.value += 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
  } else {
    error.value += err.message
  }

  // alert(error.value);
  $q.notify({
    message: error.value,
    color: 'negative',
    icon: 'mdi-alert-circle',
    position: 'top',
    timeout: 5000,
  })
}
const  paintBoundingBox = (detectedCodes:any[], ctx:any) => {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 3
    ctx.strokeStyle = '#ff8645'
    ctx.strokeRect(x, y, width, height)
  }
}
const onDetect = async ([firstDetectedCode]: any[]) => {

  const decodedString = firstDetectedCode.rawValue
  switch (commonStore.modals.qr.type) {
    case 'auth':
      const params = new URL(decodedString.replace('#', '/')).searchParams;
      const username:any = params.get('username')

      const password:any = params.get('password')
      const isCheckinIn = JSON.parse(params.get('userIsCheckinIn') || 'false')
      commonStore.hotel = params.get('hotel');
      guestStore.isCheckinIn = isCheckinIn || false

      await authStore.doLogin(username, password)

      commonStore.modals.qr.active = false
      commonStore.modals.qr.type = ''
      break
    default:
      if (isURL(decodedString)){
        alert(decodedString)
        openURL(decodedString)
      }else {
        alert(decodedString)
      }
  }
}

defineComponent({
  name: 'QRScanner',
  components: {
    QrcodeStream,
  },
});
</script>
