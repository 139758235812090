import { RouteRecordRaw } from 'vue-router';
export const homeRoutes: RouteRecordRaw[] = [
  {
    path: '/home',
    meta: {
      requiresAuth: true,
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'dashboard',
        meta: {
          requiresAuth: true,
          friendlyName: 'Home',
          icon: 'mdi-home'
        },
        name: 'dashboard',
        component: () => import('pages/home/DashboardPage.vue')
      }
    ]
  },
]
