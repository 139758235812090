import { authErrors } from 'src/utils/firebase/constants';
import { Notify } from 'quasar';
import { errorToString } from 'src/utils/helpers';
import { i18n } from 'boot/i18n';
const { t } = i18n.global;

const parse_auth_error_code = (code: string) => {
  return authErrors[code]
}

export const handle_auth_error = (error: any) => {
  const e = !!error.code ? `${error.code}: ${error.message}` : errorToString(error)
  Notify.create({
    type: 'negative',
    message: e,
  })
  throw e
}
