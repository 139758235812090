import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import routes from './routes';
import { useGuestStore } from 'stores/guest-store';
import { isEmptyObjectOrMap } from 'src/utils/helpers';
import { Loading, Notify } from 'quasar';
import { auth, getCurrentFirebaseState } from 'boot/firebase';
// import { getCurrentUser, useIsCurrentUserLoaded } from 'vuefire';
import { useAuthStore } from 'stores/auth-store';

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory);

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.beforeEach(() => {
    Loading.show()
  })
  Router.afterEach(() => {
    Loading.hide()
  })

  Router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const guestStore = useGuestStore();

// Guarda última página visitada (excepto login)
    if (to.name !== 'login') guestStore.lastPage = to.name as string;

    await auth.authStateReady()

    const currentUser = await getCurrentFirebaseState()
    const isAuthenticated = authStore.isAuthenticated;
    console.log('RouterBeforeEach is authenticated?', isAuthenticated)
    const requiresAuth = to.meta.requiresAuth;
    const userDataIsLoaded = !isEmptyObjectOrMap(guestStore.userData);

    if (requiresAuth && authStore.credentials.username && authStore.credentials.password) {
      await authStore.doLoginIfNeeded(authStore.credentials.username, authStore.credentials.password)
    }

    const isCheckinIn = guestStore.isCheckinIn;
    const checkinIsDone = guestStore.userData.checkinIsDone;
    const preCheckinIsDone = guestStore.userData.preCheckinIsDone;

    console.log('data loaded in the beforeRoute guard: ', {
      userData: guestStore.userData,
      checkinIsDone: guestStore.userData.checkinIsDone,
    })

    const logOut = async () => {
      try {
        localStorage.clear();
        sessionStorage.clear();
      } catch (error) {
        // await saveErrorLog(commonStore.hotel, '', 'logOut', error);
        console.error('Error clearing localStorage or sessionStorage', error);
      }
    };

    const goToLogin = async () => {
      await logOut();
      next({ name: 'login' });
    };

    const redirectTo = (name: string) => {
      next({ name });
    };

    const validateRoutes = () => {
      const checkinRoutes = [
        'checkin-one',
        'checkin-two',
        'checkin-three',
        'checkin-four',
        'checkin-five',
        'checkin-six',
        'checkin-seven',
        'checkin-eight',
      ];

      const routeRules: Record<string, () => void> = {
        ...checkinRoutes.reduce((acc, route) => ({
          ...acc,
          [route]: () => {
            if (preCheckinIsDone) {
              redirectTo('preCheckinDone');
            } else if (checkinIsDone) {
              redirectTo('dashboard');
            } else {
              next();
            }
          },
        }), {}),
        'preCheckinDone': () => {
          if (isCheckinIn && checkinIsDone) {
            redirectTo('dashboard');
          } else {
            next();
          }
        },
        'dashboard': () => {
          if (!checkinIsDone) {
            redirectTo('checkin-one');
          } else {
            next();
          }
        },
      };

      const rule = routeRules[to.name as string];
      if (rule) {
        rule();
      } else {
        next();
      }
    };

    try {
      if (to.name === from.name) {
        return next(false);
      }

      if (requiresAuth && !isAuthenticated) {
        Notify.create({ message: 'You are NOT authenticated, please scan your code again.', type: 'negative' });
        return await goToLogin();
      }

      if (isAuthenticated) {
        if (!userDataIsLoaded) {
          // Notify.create({ message: 'Your data has not been loaded correctly, we apologize for the inconvenience', type: 'negative' });
          await guestStore.getUserDataOnce()
          next()
          // return await logOut();
        } else {
          if (!requiresAuth) {
            return redirectTo('dashboard');
          } else {
            return validateRoutes();
          }
        }
      }
      else {
        return next();
      }
    } catch (error) {
      // await saveErrorLog(useCommonStore().hotel, '', 'RouterBeforeResolve', error);
      console.error(`Something happened while navigating to ${to.path}`, JSON.stringify(error));
      throw error
    }
  });

  return Router;
});
