import { RouteRecordRaw } from 'vue-router';
export const checkinRoutes: RouteRecordRaw[] = [
  {
    path: '/checkin',
    meta: {
      requiresAuth: true,
    },
    component: () => import('layouts/CheckinLayout.vue'),
    children: [
      // {
      //   path: 'main',
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   name: 'checkin',
      //   component: () => import('pages/checkin/CheckinPage.vue')
      // },
      {
        path: 'one',
        meta: {
          requiresAuth: true,
        },
        name: 'checkin-one',
        component: () => import('pages/checkin/CheckinOne.vue')
      },
      {
        path: 'two',
        meta: {
          requiresAuth: true,
        },
        name: 'checkin-two',
        component: () => import('pages/checkin/CheckinTwo.vue')
      },
      {
        path: 'three',
        meta: {
          requiresAuth: true,
        },
        name: 'checkin-three',
        component: () => import('pages/checkin/CheckinThree.vue')
      },
      {
        path: 'four',
        meta: {
          requiresAuth: true,
        },
        name: 'checkin-four',
        component: () => import('pages/checkin/CheckinFour.vue')
      },
      {
        path: 'five',
        meta: {
          requiresAuth: true,
        },
        name: 'checkin-five',
        component: () => import('pages/checkin/CheckinFive.vue')
      },
      {
        path: 'six',
        meta: {
          requiresAuth: true,
        },
        name: 'checkin-six',
        component: () => import('pages/checkin/CheckinSix.vue')
      },
      {
        path: 'seven',
        meta: {
          requiresAuth: true,
        },
        name: 'checkin-seven',
        component: () => import('pages/checkin/CheckinSeven.vue')
      },
      {
        path: 'eight',
        meta: {
          requiresAuth: true,
        },
        name: 'checkin-eight',
        component: () => import('pages/checkin/CheckinEight.vue')
      }
    ]
  },
]
