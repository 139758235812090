import { defineStore } from 'pinia';
import { useAuthStore } from 'stores/auth-store';
import { doc, getDoc, query, collection, onSnapshot, where, getDocs } from 'firebase/firestore';
import { db } from 'src/boot/firebase';
import { saveErrorLog } from 'src/utils/logger';
import { default as app } from '../../package.json';
import { hotelName } from 'src/utils/multiHotelsConfig';
import decodeWeather from 'src/utils/weatherCodes';
export const useCommonStore = defineStore('commonStore', {
  state: () => ({
    isDarkMode: true,
    appVersion: app.version,
    arrivalTime: <Date | null>null,
    hotelRules: null,
    weather: <any>{},
    schedules: <any>[],
    routeTransition: '',
    exchangeRate: {
      dollar: {},
      euro: {},
    },
    hotel: '',
    modals: {
      qr: {
        active: false,
        type: <null | string>null,
      }
    },
    lang: 'en',
    guestChangedLanguage: false,
  }),
  getters: {
    hotelName: (state) => {
      return hotelName[state.hotel];
    },
    getWeatherInfo(): Record<string, unknown> {
      const index = decodeWeather.findIndex(
        (x:any) => x.id === this.weather['weathercode']
      );
      return decodeWeather[index] || {};
    },
  },
  actions: {
    async getHotelRules() {
      const authStore = useAuthStore();
      try {
        const docRef = doc(db, 'misc_data', 'rules');
        const rules = await getDoc(docRef);
        this.hotelRules = rules.exists() ? rules.data()[this.hotel] : {en: [], es: []};
      } catch (e) {
        await saveErrorLog(this.hotel, authStore.uid, 'getHotelRules', e);
      }
    },
    async getWeather() {
      const authStore = useAuthStore();
      try {
        const docRef = doc(db, 'misc_data', 'weather');
        const weather = await getDoc(docRef);
        this.weather = weather.exists() ? weather.data()!['monteverde'] : null;
      } catch (e) {
        await saveErrorLog(this.hotel, authStore.uid, 'getWeather', e);
      }
    },
    async getSchedules() {
      const authStore = useAuthStore();
      try {
        const q = query(
          collection(db, 'misc_data', 'schedules', this.hotel),
          where('isActive', '==', true)
        );

        const querySnapshot = await getDocs(q);
        this.schedules.splice(0, this.schedules.length)
        querySnapshot.forEach((doc) => {
          this.schedules.push(doc.data())
        });
      } catch (e) {
        await saveErrorLog(this.hotel, authStore.uid, 'getWeather', e);
      }
    },
    async getExchangeRate() {
      const authStore = useAuthStore();
      onSnapshot(doc(db, 'misc_data', 'exchange_rates'), (doc) => {
        this.exchangeRate.dollar = doc.exists() ? doc.data().dollar : 0;
      }, async (e) => {
        await saveErrorLog(this.hotel, authStore.uid, 'getWeather', e);
      });
    },
  },
  persist: {
    key: 'common-store',
    storage: localStorage,
    paths: ['isDarkMode', 'hotel', 'lang', 'guestChangedLanguage'],
  }
});
