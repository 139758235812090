<template>
  <router-view v-slot="{ Component }">
    <transition
      appear
      :enter-active-class="`animated ${commonStore.routeTransition}`"
      mode="out-in"
    >
      <component :is="Component" :key="route.fullPath" />
    </transition>
  </router-view>
<!--  <div class="text-xl z-max">{{authStore.currentUid}}</div>-->
<!--  <PleaseRotate />-->
  <QRScanner />
</template>

<script setup lang="ts">
import { Notify, useMeta, useQuasar } from 'quasar';
import { useCommonStore } from 'stores/common-store';
import {useI18n} from 'vue-i18n';
import { onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import QRScanner from 'components/QRScanner.vue';
import { useAuthStore } from 'stores/auth-store';
import { useGuestStore } from 'stores/guest-store';
import { useRoute } from 'vue-router';
const authStore = useAuthStore();
const guestStore = useGuestStore()

const route = useRoute();

const $q = useQuasar();
const commonStore = useCommonStore();

const { locale } = useI18n({ useScope: 'global' })

watch(() => commonStore.isDarkMode, value => {
  $q.dark.set(value);
  useMeta({
    meta: {
      'theme-color': { content: value ? '#262626' : '#FFFFFF' },
    },
  })
})

watch(() => commonStore.lang, value => {
  commonStore.guestChangedLanguage = true
  locale.value = value
})
onMounted(async () => {
  $q.addressbarColor.set('#262626')
  $q.dark.set(commonStore.isDarkMode)
  //Set the device lang
  if (!commonStore.guestChangedLanguage) {
    const quasarLocale:any = $q.lang.getLocale()?.substring(0, 2) || 'en'
    commonStore.lang = ['en', 'es'].includes(quasarLocale) ? quasarLocale : 'en'
    locale.value = commonStore.lang
  }
})
onBeforeUnmount(() => {
  if (authStore.listeners.onAuthStateChanged && typeof authStore.listeners.onAuthStateChanged === 'function') {
    authStore.listeners.onAuthStateChanged();
  }
})

defineOptions({
  name: 'App'
});
</script>
