import { defineStore } from 'pinia';
import { Loading, Notify, Platform } from 'quasar';
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { db, storage, auth, getCurrentFirebaseState } from 'src/boot/firebase';

import { i18n } from 'boot/i18n';
import { useCommonStore } from 'stores/common-store';
// import { saveErrorLog } from 'src/utils/logger';
import dayjs from 'dayjs';
import { clearFirestoreObject, isEmptyObjectOrMap, isValidEmail } from 'src/utils/helpers';
import { useAuthStore } from 'stores/auth-store';
import { getIdToken } from 'firebase/auth';

const { t } = i18n.global;

// Lista de campos a excluir de userData
const excludedFields = [
  'addsAllow',
  'adsAllow',
  'allergies',
  'arrivalTime',
  'checkinLapse',
  'checkoutAt',
  'foodPlan',
  'checkinDate',
  'checkoutDate',
  'createdAt',
  'createdBy',
  'datesBetween',
  'guestLoyaltyLevel',
  'magicLogin',
  'monthsBetween',
  'inNotes',
  'platform',
  'roomNumber',
  'roomReadyTime',
  'satisfactionNote',
  'signatureURL',
  'uid',
  'noShow',
  'reservationNumber',
  'satisfied',
  'tempPassword',
  'totalCharges',
  'yearsBetween',
  'agencyCode',
  'cancelled',
  'charges',
];


export const useGuestStore = defineStore('guestStore', {
  state: () => ({
    userData: <any>{},
    lastPage: '',
    checkinData: <any>{},
    checkinStartedAt: <any>null,
    retries: {
      saveGuestSignature: 0,
      saveGuestData: 0
    },
    listeners: <any>{
      userData: () => null,
    },
    isCheckinIn: false,
  }),
  getters: {
    userDataIsLoaded(): boolean {
      return !isEmptyObjectOrMap(this.userData)
    },
    nextRoute(): string {
      switch (true) {
        case this.checkinIsDone:
          return 'dashboard';
        // case this.preCheckinIsDone && this.isCheckinIn:
        //   return 'dashboard';
        // case this.preCheckinIsDone:
        //   return 'preCheckinDone';
        default:
          return 'checkin';
      }
    },
    extraRooms(): Array<number> {
      return !!this.userData.extra_rooms ? this.userData.extra_rooms : [];
    },
    totalRooms(): number {
      return this.extraRooms.length + 1;
    },
    myRooms(): string {
      return [...this.extraRooms, this.userData.roomNumber].join(', ');
    },
    checkinIsDone(): boolean {
      return this.userDataIsLoaded ? this.userData.checkinIsDone : false;
    },
  },
  actions: {
    async savePlatform() {
      const authStore = useAuthStore()
      if (authStore.currentUid){
        const commonStore = useCommonStore();
        const guestRef = doc(
          db,
          commonStore.hotel,
          'guest',
          'reservations',
          authStore.currentUid
        );
        try {
          await updateDoc(guestRef, {
            platform: Platform.is,
            appVersion: commonStore.appVersion,
          })
        } catch (e) {
          // await saveErrorLog(commonStore.hotel, authStore.currentUid, 'savePlatform', e);
        }
      }
    },
    async saveGuestSignature(signature: string) {
      const authStore = useAuthStore()
      const currentUser:any = await getCurrentFirebaseState()
      if (!currentUser){
        console.log('For some reason the auth.currentUser is not defined');
        console.log(auth.currentUser)
        const fireState = await getCurrentFirebaseState()
        console.log(`Status de firebase: ${fireState}`)
        await authStore.reAuthenticateUser(authStore.credentials.username, authStore.credentials.password, 'none')
      }
      if (currentUser){
        console.log('saving signature')
        const commonStore = useCommonStore();
        const storageRef = ref(storage, `/guests-signatures/${commonStore.hotel}/${currentUser.uid}.png`);
        console.info(`/guests-signatures/${commonStore.hotel}/${currentUser.uid}.png`)
        const b64 = signature.substring(signature.indexOf(',') + 1);

        try {
          const snapshot = await uploadString(storageRef, b64, 'base64');
          console.log('Upload signature snapshot: ', snapshot)
          const url = await getDownloadURL(snapshot.ref)
          await this.updateData({signatureURL: url})
          console.log('signature saved')
        } catch (error) {
          // await saveErrorLog(commonStore.hotel, authStore.currentUid, 'saveSignature', error);
          Notify.create({
            type: 'negative',
            timeout: 15000,
            icon: 'mdi-alert',
            message: `Un error sucedió al intentar guardar la firma: ${error}`,
            actions: [
              {
                label: 'Ok',
                color: 'white',
                handler: () => {
                  /* ... */
                }
              }
            ]
          });
        }
      }
    },
    async updateData(data:any) {
      const commonStore = useCommonStore();
      const authStore = useAuthStore();
      const currentUser:any = await getCurrentFirebaseState()
      if (currentUser) {
        console.log('trying to update data to the db')
        console.log(`The route in firestore will be: /guest/reservations/${currentUser.uid}`)
        try {
          await updateDoc(
            // doc(db, commonStore.hotel, 'guest', 'reservations', authStore.uid), data, {auth: {token: getIdToken}}
            doc(db, commonStore.hotel, 'guest', 'reservations', currentUser.uid), data
          );
        } catch (error) {
          console.error(error);
          throw error;
        }
      } else {
        console.log('For some reason the auth.currentUser is not defined');
        console.log('trying to re-authenticate')
        await authStore.reAuthenticateUser(authStore.credentials.username, authStore.credentials.password,'none')
      }

    },
    // async saveCheckinData(guestData: any, signature:any) {
    //   const authStore = useAuthStore();
    //   if (!!authStore.currentUid){
    //     Loading.show({
    //       message: t('login.loadingMsg'),
    //     })
    //     console.log('saving checkindata')
    //     const commonStore = useCommonStore();
    //
    //     guestData.checkinLapse = {
    //       seconds: dayjs().diff(this.checkinStartedAt, 'second'),
    //       minutes: dayjs().diff(this.checkinStartedAt, 'minute'),
    //     };
    //     guestData.arrivalTime = dayjs().toDate();
    //     guestData.checkinIsDone = true// this.isCheckinIn;
    //     this.userData.checkinIsDone = true;
    //     try {
    //       console.info('Saving Guest Signature in parallel')
    //       this.saveGuestSignature(signature).then(()=> null);
    //       await updateDoc(
    //         doc(db, commonStore.hotel, 'guest', 'reservations', authStore.currentUid),
    //         clearFirestoreObject(guestData)
    //       );
    //       console.info('guest', 'reservations', authStore.currentUid)
    //       console.info('checkinData saved')
    //       this.router.push({ name: 'dashboard' });
    //     } catch (error) {
    //       // await saveErrorLog(
    //       //   commonStore.hotel,
    //       //   authStore.currentUid,
    //       //   'saveCheckinData',
    //       //   error
    //       // );
    //       Notify.create({
    //         type: 'negative',
    //         timeout: 15000,
    //         icon: 'mdi-alert',
    //         message: `An error occurred while trying to save your check-in data: ${error}`,
    //         actions: [
    //           {
    //             label: 'Ok',
    //             color: 'white',
    //             handler: () => {
    //               /* ... */
    //             },
    //           },
    //         ],
    //       });
    //       throw error
    //     } finally {
    //       Loading.hide()
    //     }
    //   }
    // },
    loadData () {
      // Destructure the properties you don't want
      const {
        addsAllow,
        adsAllow,
        allergies,
        arrivalTime,
        checkinLapse,
        checkoutAt,
        foodPlan,
        checkinDate,
        checkoutDate,
        createdAt,
        createdBy,
        datesBetween,
        guestLoyaltyLevel,
        magicLogin,
        monthsBetween,
        inNotes,
        platform,
        roomNumber,
        roomReadyTime,
        satisfactionNote,
        signatureURL,
        uid,
        noShow,
        reservationNumber,
        satisfied,
        tempPassword,
        totalCharges,
        yearsBetween,
        agencyCode,
        cancelled,
        charges,
        ...restOfUserData
      } = this.userData;

      let email = '';

      if (isValidEmail(restOfUserData.email)) {
        email = restOfUserData.email;
      }

      let mainRoomDistribution = { adults: 1, teenagers: 0, children: 0, infants: 0 };

      if (!isEmptyObjectOrMap(restOfUserData.mainRoomDistribution)){
        mainRoomDistribution = restOfUserData.mainRoomDistribution!;
      }

      let mainRoomAdditionalGuests

      if (isEmptyObjectOrMap(restOfUserData.mainRoomAdditionalGuests)){
        mainRoomAdditionalGuests = {
          adults: [],
          teenagers: [],
          children: [],
          infants: [],
        };
      } else {
        mainRoomAdditionalGuests = restOfUserData.mainRoomAdditionalGuests
      }

      let extraRoomAdditionalGuests:any = [];

      if (restOfUserData.extraRoomsAges?.length) {
        if (isEmptyObjectOrMap(restOfUserData.extraRoomAdditionalGuests)){
          restOfUserData.extraRoomsAges.forEach((val: any) => {
            extraRoomAdditionalGuests.push({
              room: val.room,
              adults: [],
              teenagers: [],
              children: [],
              infants: [],
            });
          });
        } else {
          extraRoomAdditionalGuests = restOfUserData.extraRoomAdditionalGuests;
        }

      }

      this.checkinData = {
        ...restOfUserData,
        extra_rooms: restOfUserData.extra_rooms || [],
        notes: restOfUserData.notes || null,
        // guestEmail: email,
        email: email,//restOfUserData.email,
        mainRoomAdditionalGuests,
        mainRoomDistribution,
        extraRoomAdditionalGuests
      };
    },
    async getUserDataOnce() {
      const currentUser:any = await getCurrentFirebaseState();
      if (currentUser){
        const commonStore = useCommonStore();
        const uid = currentUser.uid;
        const docRef = doc(db, commonStore.hotel, 'guest', 'reservations', uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          this.userData = docSnap.data();
          this.loadData();
        } else {
          // docSnap.data() will be undefined in this case
          console.log('No such document!');
        }
      }else {
        console.error('currentUser is null, cannot find user data');
      }

    },
    async getUserData() {
      const currentUser:any = await getCurrentFirebaseState();
      if (currentUser) {
        const uid = currentUser.uid;
        const commonStore = useCommonStore();
        this.listeners.userData();
        this.listeners.userData = onSnapshot(doc(db, commonStore.hotel, 'guest', 'reservations', uid), (doc) => {
          this.userData = doc.data();
        }, (error) => {
          Notify.create({
            timeout: 15000,
            type: 'negative',
            message: t('general.error') + ` ${JSON.stringify(error)}`,
          })
          throw error
        });
      }
    }
  },
  persist: {
    key: 'guest-store',
    storage: localStorage,
    paths: ['isCheckinIn', 'lastPage'],
  }
});
