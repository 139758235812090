import { initializeApp } from 'firebase/app';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import { initializeAuth, onAuthStateChanged, indexedDBLocalPersistence, browserLocalPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { useAuthStore } from 'stores/auth-store';
import { useCommonStore } from 'stores/common-store';
import { useGuestStore } from 'stores/guest-store';
import { boot } from 'quasar/wrappers';

import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { Loading, Notify } from 'quasar';
import * as Sentry from '@sentry/vue';
import { H } from 'highlight.run';

//REMOVE THIS ON PRODUCTION
// import { debug_token } from 'app/env/appcheck_debug';
//@ts-ignore
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = debug_token;

const firebaseConfig = {
  apiKey: 'AIzaSyDE9hKB6a-rOTrzzmP0igBehKu37hHsdPg',
  authDomain: 'treebuhotels.firebaseapp.com',
  databaseURL: 'https://treebuhotels.firebaseio.com',
  projectId: 'treebuhotels',
  storageBucket: 'treebuhotels.appspot.com',
  messagingSenderId: '675670274210',
  appId: '1:675670274210:web:a4c699a12617fde7ec27fb',
  measurementId: 'G-H1RR9WYQ0K'
};

//initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

getAnalytics(firebaseApp);
getPerformance(firebaseApp);

// initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider('6LezKdMpAAAAAIrtZbLd6jHdHSinxA-FWLQOqg4N'),
//   isTokenAutoRefreshEnabled: true
// });
//DB
export const db = getFirestore(firebaseApp);
// export const db = initializeFirestore(firebaseApp, {
//   experimentalAutoDetectLongPolling: false,
// });
//Auth
export const auth = initializeAuth(firebaseApp, {
  // persistence: indexedDBLocalPersistence,
  persistence: browserLocalPersistence,
});
//Storage
export const storage = getStorage(firebaseApp);

export const getCurrentFirebaseState = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      unsubscribe();
      // useAuthStore().user = user;
      resolve(user);
    }, reject)
  });
}

export default boot(async ({ app, router, store }) => {
  const authStore = useAuthStore()

  await auth.authStateReady()

  const fbState:any = await getCurrentFirebaseState()
  console.log('Firebase auth has initialized', fbState)

  const redirect = () => {
    const guestStore = useGuestStore();
    console.log('[firebase.ts -> redirect] guestStore.userData: ', guestStore.userData)
    const checkInIsDone = guestStore.userData.checkinIsDone;
    console.log('[firebase.ts -> redirect] is Checkin Done?: ', checkInIsDone)
    let nextPage:string = ''
    if (checkInIsDone) {
      console.log('[firebase.ts -> redirect] checkInIsDone is true so next route will be dashboard');
      nextPage = 'dashboard'
    } else {
      if (guestStore.lastPage){
        nextPage = guestStore.lastPage
      } else {
        nextPage = 'checkin-one'
      }
      console.log('[firebase.ts -> redirect] checkInIsDone is false so next route will be: ', nextPage);
    }
    console.log(`Redirecting user to ${nextPage}`)
    return router.replace({ name: nextPage });
  }

  const fetchData = async () => {
    console.log('fetching data because user is defined')
    const commonStore = useCommonStore();
    const guestStore = useGuestStore();

    try {
      const [userDataResult, hotelRulesResult] = await Promise.allSettled([
        guestStore.getUserDataOnce(),
        commonStore.getHotelRules(),
      ]);

      if (userDataResult.status === 'fulfilled') {
        // Maneja los datos del usuario
      } else {
        console.error('Error fetching user data:', userDataResult.reason);
      }

      if (hotelRulesResult.status === 'fulfilled') {
        // Maneja las reglas del hotel
      } else {
        console.error('Error fetching hotel rules:', hotelRulesResult.reason);
      }

      await redirect(); // Llama a redirect aquí
    } catch (e: any) {
      Loading.hide();
      console.error(e)
      Notify.create({
        type: 'negative',
        icon: 'mdi-alert',
        message: `An error occurred while fetching data: ${e.message}`,
        actions: [
          {
            label: 'Ok',
            color: 'white',
            handler: () => {
              /* ... */
            },
          },
        ],
      })
    } finally {
      Loading.hide();
    }
  }

  // authStore.listeners.onAuthStateChanged = onAuthStateChanged(auth, async (user) => {
  onAuthStateChanged(auth, async (user) => {
    Loading.show()
    console.log('State has changed: ', user)
    if (user) {
      authStore.uid = user.uid
      authStore.isAuthenticated = true;
      authStore.user = user;

      Sentry.setUser({ id: user.uid, email: user.email! });
      H.identify(user.email!, { id: user.uid });

      await fetchData()
      Loading.hide()
    } else {
      console.log('State is null, nothing will be done')
      authStore.user = null
      Loading.hide()
    }
  })
})
