import { RouteRecordRaw } from 'vue-router';
import { authRoutes } from 'src/router/auth';
import { checkinRoutes } from 'src/router/checkin';
import { homeRoutes } from 'src/router/guest';

const catchAll: RouteRecordRaw[] = [
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('pages/ErrorNotFound.vue'),
  },
]

const routes: RouteRecordRaw[] = [
  ...authRoutes,
  ...checkinRoutes,
  ...homeRoutes,
  ...catchAll,
];

export default routes;
