export default [
  {
    'id': 200,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Thunderstorm with light rain',
    'desc_es': 'Tormenta con lluvia ligera',
    'icon': '11'
  },
  {
    'id': 201,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Thunderstorm with rain',
    'desc_es': 'Tormenta with rain',
    'icon': '11'
  },
  {
    'id': 202,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Thunderstorm with heavy rain',
    'desc_es': 'Tormenta con fuertes lluvias',
    'icon': '11'
  },
  {
    'id': 210,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Light thunderstorm',
    'desc_es': 'Tormenta ligera',
    'icon': '11'
  },
  {
    'id': 211,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Thunderstorm',
    'desc_es': 'Tormenta',
    'icon': '11'
  },
  {
    'id': 212,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Heavy thunderstorm',
    'desc_es': 'Tormenta fuerte',
    'icon': '11'
  },
  {
    'id': 221,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Ragged thunderstorm',
    'desc_es': 'Tormenta irregular',
    'icon': '11'
  },
  {
    'id': 230,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Thunderstorm with light drizzle',
    'desc_es': 'Tormenta con llovizna ligera',
    'icon': '11'
  },
  {
    'id': 231,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Thunderstorm with drizzle',
    'desc_es': 'Tormenta con llovizna',
    'icon': '11'
  },
  {
    'id': 232,
    'main_en': 'Thunderstorm',
    'main_es': 'Tormenta',
    'desc_en': 'Thunderstorm with heavy drizzle',
    'desc_es': 'Tormenta con fuerte llovizna',
    'icon': '11'
  },

  {
    'id': 300,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Light intensity drizzle',
    'desc_es': 'Llovizna de intensidad ligera',
    'icon': '09'
  },
  {
    'id': 301,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Drizzle',
    'desc_es': 'Llovizna',
    'icon': '09'
  },
  {
    'id': 302,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Heavy intensity drizzle',
    'desc_es': 'Llovizna de intensidad fuerte',
    'icon': '09'
  },
  {
    'id': 310,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Light intensity drizzle rain',
    'desc_es': 'Lluvia o llovizna de intensidad ligera',
    'icon': '09'
  },
  {
    'id': 311,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Drizzle rain',
    'desc_es': 'Lluvia fina',
    'icon': '09'
  },
  {
    'id': 312,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Heavy intensity drizzle rain',
    'desc_es': 'Llovizna de intensidad fuerte',
    'icon': '09'
  },
  {
    'id': 313,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Shower rain and drizzle',
    'desc_es': 'Chubasco / Aguacero',
    'icon': '09'
  },
  {
    'id': 314,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Heavy shower rain and drizzle',
    'desc_es': 'Chubascos fuertes y llovizna',
    'icon': '09'
  },
  {
    'id': 321,
    'main_en': 'Drizzle',
    'main_es': 'Llovizna',
    'desc_en': 'Shower drizzle',
    'desc_es': 'Chubascos y llovizna',
    'icon': '09'
  },

  {
    'id': 500,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Light rain',
    'desc_es': 'Lluvia ligera',
    'icon': '10'
  },
  {
    'id': 501,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Moderate rain',
    'desc_es': 'Lluvia moderada',
    'icon': '10'
  },
  {
    'id': 502,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Heavy intensity rain',
    'desc_es': 'Lluvia de fuerte intensidad',
    'icon': '10'
  },
  {
    'id': 503,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Very heavy rain',
    'desc_es': 'Lluvia muy fuerte',
    'icon': '10'
  },
  {
    'id': 504,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Extreme rain',
    'desc_es': 'Lluvia extrema',
    'icon': '10'
  },
  {
    'id': 511,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Freezing rain',
    'desc_es': 'Lluvia congelada',
    'icon': '13'
  },
  {
    'id': 520,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Light intensity shower rain',
    'desc_es': 'Chubascos ligeros',
    'icon': '09'
  },
  {
    'id': 521,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Shower rain',
    'desc_es': 'Chubascos',
    'icon': '09'
  },
  {
    'id': 522,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Heavy intensity shower rain',
    'desc_es': 'Chubascos fuertes',
    'icon': '09'
  },
  {
    'id': 531,
    'main_en': 'Rain',
    'main_es': 'Lluvia',
    'desc_en': 'Ragged shower rain',
    'desc_es': 'Chubascos irregulares',
    'icon': '09'
  },


  {
    'id': 700,
    'main_en': 'Mist',
    'main_es': 'Neblina',
    'desc_en': 'Mist',
    'desc_es': 'Neblina',
    'icon': '50'
  },
  {
    'id': 711,
    'main_en': 'Smoke',
    'main_es': 'Neblina',
    'desc_en': 'Smoke',
    'desc_es': 'Neblina',
    'icon': '50'
  },
  {
    'id': 721,
    'main_en': 'Haze',
    'main_es': 'Neblina',
    'desc_en': 'Haze',
    'desc_es': 'Neblina',
    'icon': '50'
  },
  {
    'id': 731,
    'main_en': 'Dust',
    'main_es': 'Neblina',
    'desc_en': 'Sand/ dust whirls',
    'desc_es': 'Neblina',
    'icon': '50'
  },
  {
    'id': 741,
    'main_en': 'Fog',
    'main_es': 'Neblina',
    'desc_en': 'Fog',
    'desc_es': 'Neblina',
    'icon': '50'
  },
  {
    'id': 751,
    'main_en': 'Sand',
    'main_es': 'Neblina',
    'desc_en': 'Sand',
    'desc_es': 'Neblina',
    'icon': '50'
  },
  {
    'id': 761,
    'main_en': 'Dust',
    'main_es': 'Polvo',
    'desc_en': 'Dust',
    'desc_es': 'Polvo',
    'icon': '50'
  },
  {
    'id': 762,
    'main_en': 'Ash',
    'main_es': 'Ceniza',
    'desc_en': 'Volcanic ash',
    'desc_es': 'Ceniza Volcánica',
    'icon': '50'
  },
  {
    'id': 771,
    'main_en': 'Squall',
    'main_es': 'Borrasca',
    'desc_en': 'Squall',
    'desc_es': 'Borrasca',
    'icon': '50'
  },
  {
    'id': 781,
    'main_en': 'Tornado',
    'main_es': 'Tornado',
    'desc_en': 'Tornado',
    'desc_es': 'Tornado',
    'icon': '50'
  },

  {
    'id': 800,
    'main_en': 'Clear',
    'main_es': 'Despejado',
    'desc_en': 'Clear sky',
    'desc_es': 'Cielo despejado',
    'icon': '01'
  },

  {
    'id': 801,
    'main_en': 'Clouds',
    'main_es': 'Nublado',
    'desc_en': 'Few clouds: 11-25%',
    'desc_es': 'Poco nublado: 11-25%',
    'icon': '02'
  },
  {
    'id': 802,
    'main_en': 'Clouds',
    'main_es': 'Nublado',
    'desc_en': 'Scattered clouds: 25-50%',
    'desc_es': 'Nubes dispersas: 25-50%',
    'icon': '03'
  },
  {
    'id': 803,
    'main_en': 'Clouds',
    'main_es': 'Nublado',
    'desc_en': 'Broken clouds: 51-84%',
    'desc_es': 'Nubes rotas: 51-84%',
    'icon': '04'
  },
  {
    'id': 804,
    'main_en': 'Clouds',
    'main_es': 'Nublado',
    'desc_en': 'Overcast clouds: 85-100%',
    'desc_es': 'Muy nublado: 85-100%',
    'icon': '04'
  }

]
