export const domains: Record<string, unknown> = {
  hpp: 'https://treebu.app',
  mcl: 'https://treebu.app',
  mrl: 'https://treebu.app',
};

export const hotelName: Record<string, unknown> = {
  hpp: <string>'Hotel & Spa Poco a Poco',
  mcl: <string>'Monteverde Country Lodge',
  mrl: <string>'Sibū Lodge',
};

export const websites: Record<string, unknown> = {
  hpp: 'https://hotelpocoapoco.com',
  mcl: 'https://monteverdecountrylodge.com',
  mrl: 'https://monteverderusticlodge.com',
};

export const data: Record<string, any> = {
  hpp: {
    phone: '+50689890758',
  },
  mcl: {
    phone: '+50683657175',
  },
  mrl: {
    phone: '+50689648888',
  },
};

export default {
  domains,
  data,
};
