import {urlValidator} from 'src/utils/regex';
import {signOut, getAuth} from 'firebase/auth';
import {Notify, Dialog} from 'quasar';
import dayjs from 'dayjs';
import { i18n } from 'boot/i18n';
const { t } = i18n.global;

export const errorToString = (err: any): string => {
  return JSON.stringify(err, Object.getOwnPropertyNames(err));
}
export const reservationRoomTotalPax = (row: any) => {

  const mainPax = row.paxMainRoom;

  let extraPax = 0;
  if (row.extraRoomsAges !== null) {
    extraPax = row.extraRoomsAges.reduce((acc:any, obj:any) => {
      return acc + (obj.adults + obj.children + obj.infants + obj.teenagers)
    }, 0)
  }

  return mainPax + extraPax;
};
export const isEmptyObjectOrMap = (value: object | Map<any, any> | null | undefined | ''): boolean => {
  if (!value) {
    // Checks if value is null, undefined, or empty string
    return true;
  }

  if (value instanceof Map) {
    // Checks for empty Map
    return value.size === 0;
  }

  // Checks for empty object.
  // TypeScript ensures value can only be an object, Map, null, undefined, or empty string at this point,
  // so we can use Object.keys without additional type checking.
  return Object.keys(value as object).length === 0;
};

export const sanitizeObject = (obj: any) => {
  // We need to parse string back to object and return it
  return JSON.stringify(obj);
  //return JSON.parse(stringified);
};

export const isURL = (str:string) => {
  return urlValidator.test(str)
}

export const forceReload = () => {
  window.location.reload();
};

export const clearCache = () => {
  localStorage.clear();
  sessionStorage.clear();
}

export const logOut = async (withoutConfirmation = false) => {
  if (withoutConfirmation) {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        forceReload();
      })
      .catch((error) => {
        Notify.create({
          type: 'negative',
          message: error,
        });
      });
  } else {
    Dialog.create({
      title: t('general.logoutConfirmation.title'),
      message: t('general.logoutConfirmation.message'),
      cancel: true,
      persistent: true
    }).onOk(async () => {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          localStorage.clear();
          sessionStorage.clear();
          forceReload();
        })
        .catch((error) => {
          Notify.create({
            type: 'negative',
            message: error
          });
        });
    });
  }
};

export const clearFirestoreObject = (obj: any) => {
  const keys = Object.keys(obj);
  keys.forEach((x) => {
    obj[x] = isValidFirestoreDataType(obj[x]) ? obj[x] : null;
  });
  return obj;
};
export const isValidFirestoreDataType = (value: any) => {
  return (
    isUnidimensionalArray(value) ||
    isBoolean(value) ||
    isDate(value) ||
    isNumber(value) ||
    isNull(value) ||
    isString(value) ||
    isPureObject(value)
  );
};

const isUnidimensionalArray = (arr: any) => {
  return Array.isArray(arr) && arr.every((x) => !Array.isArray(x));
};
const isBoolean = (bool: any) => {
  return typeof bool === 'boolean';
};
const isDate = (date: any) => {
  return date instanceof Date && date.getTime();
};
const isNumber = (number: any) => {
  return typeof number === 'number' && isFinite(number);
};
const isNull = (nullValue: any) => {
  return nullValue === null;
};
const isString = (string: any) => {
  return typeof string === 'string';
};
const isPureObject = (obj: any) => {
  return obj ? Object.getPrototypeOf(obj) == Object.prototype : false;
};
export const datesBetween = (checkinDate: any, checkoutDate: any) => {
  const datesBetween: any = [];
  const diff = checkoutDate.diff(checkinDate.toDate(), 'day');
  for (let i = 0; i <= diff; i++) {
    datesBetween.push(checkinDate.add(i, 'day').toDate());
  }
  return datesBetween;
};
export const monthsBetween = (checkinDate:any, checkoutDate:any) => {
  const monthsBetween: any = [];
  const monthYear1 = checkinDate.format('MM/YY');
  const monthYear2 = checkoutDate.format('MM/YY');
  if (!monthsBetween.includes(monthYear1)) {
    monthsBetween.push(monthYear1);
  }
  if (!monthsBetween.includes(monthYear2)) {
    monthsBetween.push(monthYear2);
  }
  return monthsBetween;
};

export const dayTimeGreeting = () => {
  const currentHour = parseInt(dayjs().format('HH'));
  return currentHour < 12
    ? 'general.morning'
    : currentHour <= 17
      ? 'general.afternoon'
      : 'general.evening';
};

export const convertToF = (celsius: any) => {
  return (celsius * 9) / 5 + 32;
};
export const groupBy = (key: any, array: any) =>
  array.reduce((objectsByKeyValue: any, obj: any) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const parseDate = (date: any) => {
  // //console.log(date)
  return dayjs(date).format('DD/MM/YYYY');
};

export const firestoreToDate = (date: any) => {
  return new Date(date.seconds * 1000 + date.nanoseconds / 100000);
};

export const rightNow = () => {
  return dayjs().toDate();
};
export const yearsBetween = (checkinDate:any, checkoutDate:any) => {
  const yearsBetween: any = [];
  const year1 = checkinDate.format('YYYY');
  const year2 = checkoutDate.format('YYYY');
  if (!yearsBetween.includes(year1)) {
    yearsBetween.push(year1);
  }
  if (!yearsBetween.includes(year2)) {
    yearsBetween.push(year2);
  }
  return yearsBetween;
};
export const removeDiacritics = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
export const normalizedContains = (needle: string, haystack: string) => {
  const regExp = new RegExp(removeDiacritics(needle), 'gi');
  return regExp.test(removeDiacritics(haystack));
};

export const isValidEmail = (email = '') => {
  if (!!email) {
    const checkEmail = email.split('@')[1];
    return (
      checkEmail !== undefined &&
      checkEmail !== '' &&
      checkEmail !== 'email.com' &&
      checkEmail !== 'no-email.com' &&
      checkEmail !== 'guest.booking.com' &&
      checkEmail !== 'm.expediapartnercentral.com' &&
      validateEmail(email) &&
      checkEmail !== 'treebuhotels.com'
    );
  } else {
    return false;
  }
};
export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const capitalizeFirstLetter = (string: string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};
export const capitalizeTheFirstLetterOfEachWord = (words:any) => {
  const separateWord = words.toLowerCase().split(' ');
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1).toLowerCase();
  }
  return separateWord.join(' ');
};
